import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HintComponent } from '../../../../components/hint/hint.component';
import { TooltipPositionType } from '../../../../types/tooltip-position.type';

@Component({
  selector: 'app-form-label',
  template: `
    <label *ngIf="label.length > 0" class="form-label">
      {{ label }}
      <app-hint
        *ngIf="tooltip"
        [description]="tooltip"
        [position]="tooltipPosition"
        class="form-label__hint"
      ></app-hint>
    </label>
  `,
  styleUrls: ['./form-label.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HintComponent,
  ],
})
export class FormLabelComponent {
  @Input() label = '';
  @Input() tooltip: string | undefined;
  @Input() tooltipPosition: TooltipPositionType = 'bottom';
}
