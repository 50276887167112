export const FORM_FEEDBACK_MESSAGES: {
  email: string;
  required: string;
  wrongNumber: string;
  onlyLowerCase: string;
  noNumbers: string;
  noCapitalLetters: string;
  incorrectPassword: string;
} = {
  email: $localize`E-mail address is not correct`,
  required: $localize`This field is required`,
  wrongNumber: $localize`Enter a valid phone number with a country code`,
  onlyLowerCase: $localize`Only lowercase and space allowed`,
  noNumbers: $localize`No numbers allowed`,
  noCapitalLetters: $localize`No capital letters allowed`,
  incorrectPassword: $localize`Unable to log in with provided credentials`,
};
